var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'player-margin': _vm.isAuthenticated && !_vm.isMobile() && !this.initialPlay,
  }},[(_vm.sections)?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"row-eq-height"},[_vm._l((_vm.filter_applied ? _vm.filtered_sections : _vm.sections),function(section){return _c('div',{key:section.id,staticClass:"col-12 col-lg-3 p-0 section wide-section",class:{
            'col-lg-12': _vm.showByBlockIndex === section.id,
            'd-none': _vm.rowNeighbours.includes(section),
          },on:{"mouseover":function($event){_vm.showByIndex = section.id},"mouseout":function($event){_vm.showByIndex = null},"click":function($event){return _vm.clickedSection(section)}}},[_c('div',{staticClass:"image-backdrop hero-banner overlay",style:({
              backgroundImage: ("url(" + (_vm.getImagePath(section.image, {
                width: _vm.screen_width,
              })) + ")"),
            })}),_c('div',{staticClass:"hero-banner overlay"},[_c('div',{staticClass:"w-100 h-100 text-center",class:{
                overlay:
                  _vm.showByIndex === section.id || _vm.expanded === section.id,
                'square-normal-overlay': _vm.showByIndex !== section.id,
              },staticStyle:{"position":"relative"}},[(!_vm.isMobile())?_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"section-top",attrs:{"cols":"6"}},[(
                      section.chipin_enabled &&
                        !section.chipin_plus &&
                        section.type === 'artist'
                    )?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"},{name:"b-modal",rawName:"v-b-modal",value:('chipin-modal-' + section.id),expression:"'chipin-modal-' + section.id"}],staticClass:"p-0 mt-0 float-left",attrs:{"variant":"link"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("../assets/chipin-badge.svg"),"height":"35","alt":"ChipIn Logo"}})]):_vm._e(),(section.trackd_official)?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"p-0 mt-0 float-left",attrs:{"variant":"link"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("../assets/trackd-icon-colours.svg"),"height":"35","alt":"Trackd Logo"}})]):_vm._e(),(
                      section.chipin_enabled &&
                        section.chipin_plus &&
                        section.type === 'artist'
                    )?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"},{name:"b-modal",rawName:"v-b-modal",value:('chipin-plus-modal-' + section.id),expression:"'chipin-plus-modal-' + section.id"}],staticClass:"p-0 mt-0 float-left",attrs:{"variant":"link"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("../assets/chipin-plus-badge.svg"),"height":"35","alt":"ChipIn+ Logo"}})]):_vm._e()],1),_c('b-col',{staticClass:"section-top",attrs:{"cols":"6"}},[(_vm.expanded === section.id)?_c('b-button',{staticClass:"p-0 mt-0 float-right close-button",attrs:{"variant":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("../assets/section-close.svg"),"height":"23","alt":"Close"}})]):_vm._e()],1),_c('b-col',{staticClass:"text-center px-0 text-light c-item-information",class:{ 'mt-3': _vm.showByBlockIndex !== section.id },staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-md-4",class:{
                      'section-expanded': _vm.expanded === section.id,
                      'section-normal': _vm.expanded !== section.id,
                    }},[(
                        section.chipin_enabled &&
                          !section.chipin_plus &&
                          section.type === 'artist'
                      )?_c('chipin',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],attrs:{"user_id":section.id,"username":section.username}}):_vm._e(),(
                        section.chipin_enabled &&
                          section.chipin_plus &&
                          section.type === 'artist'
                      )?_c('chipinplus',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],attrs:{"user_id":section.id,"username":section.username,"stripe_type":section.made_on_stripe_account}}):_vm._e(),(!_vm.largeScreen)?_c('div',{staticClass:"section-info",class:{ 'mt-3': _vm.expanded === section.id }},[(_vm.expanded !== section.id)?[_c('h1',{staticClass:"font-weight-lighter heading-text mb-0",domProps:{"innerHTML":_vm._s(_vm.elipsis(section.heading, 24))}},[_vm._v(" "+_vm._s(section.heading)+" ")]),(section.type === 'librarysection')?_c('p',{staticClass:"c-subtitle-asset type-text small pb-0 mb-0 text-uppercase"},[_vm._v(" Discover ")]):_c('p',{staticClass:"c-subtitle-asset type-text small pb-0 mb-0  text-uppercase"},[_vm._v(" "+_vm._s(section.type)+" ")])]:_vm._e(),(_vm.expanded === section.id)?[(section.type === 'librarysection')?_c('p',{staticClass:"\n                          c-subtitle-asset\n                          type-text\n                          small\n                          pb-0\n                          mb-0\n                          text-uppercase\n                        "},[_vm._v(" Discover ")]):_c('p',{staticClass:"\n                            c-subtitle-asset\n                            type-text\n                            small\n                            pb-0\n                            mb-0\n                            text-uppercase\n                          "},[_vm._v(" "+_vm._s(section.type)+" ")]),_c('h1',{staticClass:"font-weight-lighter heading-text-expanded mb-0 mt-lg-3",class:{
                            'mobile-heading-expanded':
                              section.heading.length > 18,
                          }},[_vm._v(" "+_vm._s(section.heading)+" ")]),(_vm.expanded === section.id)?_c('p',{staticClass:"font-weight-lighter mb-0 section-text"},[_vm._v(" "+_vm._s(section.text)+" ")]):_vm._e()]:_vm._e()],2):[(_vm.expanded !== section.id)?_c('h1',{staticClass:"font-weight-lighter heading-text",domProps:{"innerHTML":_vm._s(_vm.elipsis(section.heading, 24))}},[_vm._v(" "+_vm._s(section.heading)+" ")]):_vm._e(),(section.type === 'librarysection')?_c('p',{staticClass:"\n                          c-subtitle-asset\n                          type-text\n                          subtitle-text\n                          pb-0\n                          mb-0\n                           text-uppercase\n                        "},[_vm._v(" Discover ")]):_c('p',{staticClass:"\n                         text-uppercase\n                         type-text\n                          c-subtitle-asset\n                          subtitle-text\n                          pb-0\n                          mb-0\n                        "},[_vm._v(" "+_vm._s(section.type)+" ")]),(_vm.expanded === section.id)?_c('h1',{staticClass:"font-weight-lighter heading-text heading-text-expanded mt-lg-4",domProps:{"innerHTML":_vm._s(_vm.elipsis(section.heading, 24))}},[_vm._v(" "+_vm._s(section.heading)+" ")]):_vm._e(),(_vm.expanded === section.id)?_c('p',{staticClass:"font-weight-lighter mb-0 section-text mt-3"},[_vm._v(" "+_vm._s(section.text)+" ")]):_vm._e()]],2)])],1):_vm._e(),(_vm.isMobile())?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.showByIndex === section.id ||
                    _vm.showByBlockIndex === section.id
                ),expression:"\n                  showByIndex === section.id ||\n                    showByBlockIndex === section.id\n                "}],staticClass:"d-block mx-auto text-center pt-5",staticStyle:{"position":"relative"}},[_c('h1',{staticClass:"text-light font-weight-lighter",staticStyle:{"font-size":"1.5em"}},[_vm._v(" "+_vm._s(section.heading)+" ")]),_c('p',{staticClass:"text-light font-weight-lighter",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(section.text)+" ")]),_c('div',[(section.type === 'song')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"\n                      text-light\n                      px-3\n                      font-weight-light\n                      view-button-mobile\n                      button-grey\n                    ",attrs:{"size":"sm","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'Song',
                        params: { id: section.id },
                      })}}},[_vm._v("View Song")]):_vm._e(),(section.type === 'artist')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"\n                      text-light\n                      font-weight-light\n                      view-button-mobile\n                      button-blue\n                    ",attrs:{"size":"sm","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'ArtistUsername',
                        params: { id: section.username },
                      })}}},[_vm._v("View Artist")]):_vm._e(),(section.type === 'playlist')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"text-light font-weight-light view-button-mobile",attrs:{"size":"sm","variant":"warning","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'Playlist',
                        params: { id: section.id },
                      })}}},[_vm._v("View Playlist")]):_vm._e(),(section.type === 'genre')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"\n                      text-light\n                      px-4\n                      font-weight-light\n                      view-button-mobile\n                    ",attrs:{"size":"sm","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'LibrarySubSectionList',
                        params: { id: section.id },
                        query: { type: section.type },
                      })}}},[_vm._v("View")]):_vm._e(),(section.type === 'librarysection')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"\n                      text-light\n                      px-4\n                      font-weight-light\n                      view-button-mobile\n                    ",attrs:{"size":"sm","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'LibrarySectionList',
                        params: { area: section.side, id: section.id },
                      })}}},[_vm._v("View")]):_vm._e(),(section.type === 'location')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === section.id),expression:"showByIndex === section.id"}],staticClass:"\n                      text-light\n                      px-4\n                      font-weight-light\n                      view-button-mobile\n                    ",attrs:{"size":"sm","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                        name: 'LibrarySubSection',
                        params: { id: section.id },
                        query: { type: section.location_type },
                      })}}},[_vm._v("View")]):_vm._e(),[(
                        section.type === 'song' && _vm.expanded === section.id
                      )?_c('playerbutton',{attrs:{"id":section.id,"file":section.file,"title":section.heading,"username":section.username},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):_vm._e(),(
                        section.type !== 'song' && _vm.expanded === section.id
                      )?_c('playerbutton',{attrs:{"id":section.id,"username":section.username,"type":section.type},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):_vm._e()]],2)]):_vm._e()],1),_c('div',{staticClass:"button-margin"},[(section.type === 'artist')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          \n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-blue\n                        ",attrs:{"size":"lg","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'ArtistUsername',
                    params: { id: section.username },
                  })}}},[_vm._v("View Artist")]):_vm._e(),(section.type === 'song')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          \n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-grey\n                        ",attrs:{"size":"lg","variant":"secondary","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'Song',
                    params: { id: section.id },
                  })}}},[_vm._v("View Song")]):_vm._e(),(section.type === 'playlist')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          \n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-orange\n                        ",attrs:{"size":"lg","variant":"warning","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'Playlist',
                    params: { id: section.id },
                  })}}},[_vm._v("View Playlist")]):_vm._e(),(section.type === 'genre')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          px-4\n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-blue\n                        ",attrs:{"size":"lg","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'LibrarySubSectionList',
                    params: { id: section.id },
                    query: { type: section.type },
                  })}}},[_vm._v("View")]):_vm._e(),(section.type === 'librarysection')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          px-4\n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-blue\n                        ",attrs:{"size":"lg","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'LibrarySectionList',
                    params: { area: section.side, id: section.id },
                  })}}},[_vm._v("View")]):_vm._e(),(section.type === 'location')?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByBlockIndex === section.id),expression:"showByBlockIndex === section.id"}],staticClass:"\n                          text-light\n                          py-2\n                          px-4\n                          mt-lg-4\n                          mt-md-0\n                          font-weight-light\n                          view-button\n                          button-blue\n                        ",attrs:{"size":"lg","variant":"info","squared":""},on:{"click":function($event){return _vm.viewSection({
                    name: 'LibrarySubSection',
                    params: { id: section.id },
                    query: { type: section.location_type },
                  })}}},[_vm._v("View")]):_vm._e(),_c('span',[(section.type === 'song' && _vm.expanded === section.id)?_c('playerbutton',{attrs:{"id":section.id,"file":section.file,"title":section.heading,"username":section.username,"type":'song'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):(
                    section.type === 'playlist' && _vm.expanded === section.id
                  )?_c('playerbutton',{attrs:{"id":section.id,"queue":section.songs,"title":section.heading,"username":section.username,"type":'playlist'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):(
                    section.type === 'artist' && _vm.expanded === section.id
                  )?_c('playerbutton',{attrs:{"id":section.id,"queue":section.featured_tracks,"title":section.heading,"username":section.username,"type":'artist'},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):(_vm.expanded === section.id)?_c('playerbutton',{attrs:{"id":section.id,"username":section.username,"type":section.type},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.closeSection()}}}):_vm._e()],1)],1)])])}),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }