<template>
  <div class="d-inline">
    <!-- Desktop -->
    <template v-if="!isMobile()">
      <b-button
        v-if="playing && id === getLoadedSong"
        @click="pause"
        size="lg"
        variant="danger"
        class="text-light py-2 mt-lg-4 px-3 ml-3 font-weight-light player-button"
        squared
      >
        <span>Pause</span>
      </b-button>

      <b-button
        v-else
        @click="play"
        size="(size !== null) ? size : lg"
        variant="danger"
        class="text-light py-2 px-3 mt-lg-4 ml-3 font-weight-light player-button"
        squared
      >
        Play
      </b-button>
    </template>

    <!-- Mobile -->
    <template v-else>
      <b-button
        v-if="playing && id === getLoadedSong"
        @click="pause"
        size="lg"
        variant="danger"
        class="text-light px-3 ml-3 font-weight-light player-button-mobile"
        squared
      >
        <span>Pause</span>
      </b-button>

      <b-button
        v-else
        @click="play"
        size="(size !== null) ? size : lg"
        variant="danger"
        class="text-light px-3 ml-3 font-weight-light player-button-mobile"
        squared
      >
        Play
      </b-button>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["image", "file", "title", "username", "id", "size", "type", "queue"],
  data: function() {
    return {
      song: null,
      // queue: [],
    };
  },
  methods: {
    ...mapMutations("Player", [
      "playSong",
      "pauseSong",
      "setSong",
      "addSong",
      "clearQueue",
      "setCurrentPlaylist",
      "setPlayAfterAuth",
    ]),
    ...mapActions("Artist", { artistapi: "artist", artistwebapi: "artistweb" }),
    ...mapActions("Playlist", ["get_playlist"]),
    ...mapActions("Library", ["librarysubsectionlist"]),
    ...mapGetters(["isAuthenticated"]),
    play: function() {
        var data = this.song

        if (!this.isAuthenticated()) {
          this.$bvModal.show("login-modal");
          this.$store.state.nextUrl = {name: 'Home'}
          this.setPlayAfterAuth({shouldPlay: true, songToPlay: this.song})
          data['preview'] = true
        } else {
          data['preview'] = false

          if (this.loadedSong && this.id !== this.getLoadedSong) {    
            this.pauseSong();
            this.setSong(data);
            this.playSong();
          } else if (!this.loadedSong) {
            this.setSong(data);
          } else {
            this.playSong();
          }
        }

        
    },
    pause: function() {
      this.pauseSong();
    },

    loadQueue(queue) {
      // Set the queue in the player store
      this.clearQueue();

      if (this.type === "playlist") {
        this.setCurrentPlaylist(this.id);
      }
      for (const song of queue) {
        if (song.id !== this.getLoadedSong) {
          this.addSong(song);
        }
      }
    },

    getArtistSongs() {
      var vm = this;
      this.artistwebapi(this.username)
        .then((res) => {
          var songs = res.data.featured_tracks;
          vm.song = songs[0];
          songs.shift();
          vm.queue = songs;
        })
        .catch((error) => {
          error;
          
        });
    },
    getPlaylistSongs() {
      var vm = this;
      this.get_playlist(this.id)
        .then((res) => {
          var songs = res.data.songs;
          vm.song = songs[0];
          songs.shift();
          vm.queue = songs;
        })
        .catch((error) => {
          error
          
        });
    },
  },
  computed: {
    ...mapGetters("Player", {
      paused: "isPaused",
      playing: "isPlaying",
      loadedSong: "loadedSong",
      getLoadedSong: "getLoadedSong",
      shouldPlayAfterAuth: "shouldPlayAfterAuth",
      getPlayAfterAuth: "getPlayAfterAuth",
    }),
  },
  mounted() {
    if (this.type === "song") [(this.song = this.$props)];

    if (this.type === "artist" || this.type === "playlist") {
      this.song = this.queue.shift();
      this.loadQueue(this.queue);
    }
  },
};
</script>

<style scoped>
.player-button-mobile {
  width: 106px;
  height: 40px;
  font-size: 0.9em;
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.player-button {
  border: none;
  width: 106px;
  height: 40px;
  font-size: 0.9em;
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.play-icon {
  display: none;
}

@media (min-width: 768px) {
  .player-button {
    border: none;
    width: 134px;
    height: 52px;
    font-size: 24px;
  }

  .play-icon {
    display: inline;
  }
}

@media (min-width: 992px) {
  .player-button {
    width: 187px;
    height: 60px;
    font-size: 1.6em;
  }
}
</style>
