<template>
  <div
    :class="{
      'player-margin': isAuthenticated && !isMobile() && !this.initialPlay,
    }"
  >
    <b-container fluid v-if="sections">
      <b-row class="row-eq-height">
        <template>
          <div
            class="col-12 col-lg-3 p-0 section wide-section"
            v-for="section in filter_applied ? filtered_sections : sections"
            :key="section.id"
            @mouseover="showByIndex = section.id"
            @mouseout="showByIndex = null"
            @click="clickedSection(section)"
            v-bind:class="{
              'col-lg-12': showByBlockIndex === section.id,
              'd-none': rowNeighbours.includes(section),
            }"
          >
            <div
              class="image-backdrop hero-banner overlay"
              :style="{
                backgroundImage: `url(${getImagePath(section.image, {
                  width: screen_width,
                })})`,
              }"
            ></div>
            <div class="hero-banner overlay">
              <div
                class="w-100 h-100 text-center"
                v-bind:class="{
                  overlay:
                    showByIndex === section.id || expanded === section.id,
                  'square-normal-overlay': showByIndex !== section.id,
                }"
                style="position:relative;"
              >
                <!-- DESKTOP -->
                <b-row v-if="!isMobile()" class="mx-0">
                  <b-col cols="6" class="section-top">
                    <b-button
                      v-show="showByBlockIndex === section.id"
                      v-if="
                        section.chipin_enabled &&
                          !section.chipin_plus &&
                          section.type === 'artist'
                      "
                      class="p-0 mt-0 float-left"
                      variant="link"
                      v-b-modal="'chipin-modal-' + section.id"
                    >
                      <img
                        src="../assets/chipin-badge.svg"
                        class="mx-auto"
                        height="35"
                        alt="ChipIn Logo"
                      />
                    </b-button>
                    <b-button
                      v-show="showByBlockIndex === section.id"
                      v-if="section.trackd_official"
                      class="p-0 mt-0 float-left"
                      variant="link"
                    >
                      <img
                        src="../assets/trackd-icon-colours.svg"
                        class="mx-auto"
                        height="35"
                        alt="Trackd Logo"
                      />
                    </b-button>
                    <b-button
                      v-show="showByBlockIndex === section.id"
                      v-if="
                        section.chipin_enabled &&
                          section.chipin_plus &&
                          section.type === 'artist'
                      "
                      class="p-0 mt-0 float-left"
                      variant="link"
                      v-b-modal="'chipin-plus-modal-' + section.id"
                    >
                      <img
                        src="../assets/chipin-plus-badge.svg"
                        class="mx-auto"
                        height="35"
                        alt="ChipIn+ Logo"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="6" class="section-top">
                    <b-button
                      class="p-0 mt-0 float-right close-button"
                      v-on:click.stop="closeSection()"
                      variant="link"
                      v-if="expanded === section.id"
                    >
                      <img
                        src="../assets/section-close.svg"
                        class="mx-auto"
                        height="23"
                        alt="Close"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    class="text-center px-0 text-light c-item-information"
                    :class="{ 'mt-3': showByBlockIndex !== section.id }"
                    style="position:relative;"
                  >
                    <!-- Show pause overlay if this section is playing -->
                    <!-- <div v-if="getLoadedSongData.username === section.username && playing" class="pt-5 mt-4">
                                        <b-button variant="link" @click.stop="pauseSong">
                                            <img class="" src="../assets/pause.svg" width="30px" alt="placeholder" />
                                        </b-button>
                                    </div> -->

                    <div
                      class="mt-md-4"
                      :class="{
                        'section-expanded': expanded === section.id,
                        'section-normal': expanded !== section.id,
                      }"
                    >
                      <chipin
                        v-show="showByBlockIndex === section.id"
                        v-if="
                          section.chipin_enabled &&
                            !section.chipin_plus &&
                            section.type === 'artist'
                        "
                        v-bind:user_id="section.id"
                        v-bind:username="section.username"
                      ></chipin>
                      <chipinplus
                        v-show="showByBlockIndex === section.id"
                        v-if="
                          section.chipin_enabled &&
                            section.chipin_plus &&
                            section.type === 'artist'
                        "
                        v-bind:user_id="section.id"
                        v-bind:username="section.username"
                        v-bind:stripe_type="section.made_on_stripe_account"
                      ></chipinplus>
                      <div
                        v-if="!largeScreen"
                        class="section-info"
                        :class="{ 'mt-3': expanded === section.id }"
                      >
                        <template v-if="expanded !== section.id">
                          <h1
                            class="font-weight-lighter heading-text mb-0"
                            v-html="elipsis(section.heading, 24)"
                          >
                            {{ section.heading }}
                          </h1>
                          <p
                            v-if="section.type === 'librarysection'"
                            class="c-subtitle-asset type-text small pb-0 mb-0 text-uppercase"
                          >
                            Discover
                          </p>
                          <p
                            v-else
                            class="c-subtitle-asset type-text small pb-0 mb-0  text-uppercase"
                          >
                            {{ section.type }}
                          </p>
                        </template>

                        <template v-if="expanded === section.id">
                          <p
                            v-if="section.type === 'librarysection'"
                            class="
                            c-subtitle-asset
                            type-text
                            small
                            pb-0
                            mb-0
                            text-uppercase
                          "
                          >
                            Discover
                          </p>
                          <p
                            v-else
                            class="
                              c-subtitle-asset
                              type-text
                              small
                              pb-0
                              mb-0
                              text-uppercase
                            "
                          >
                            {{ section.type }}
                          </p>
                          <h1
                            class="font-weight-lighter heading-text-expanded mb-0 mt-lg-3"
                            :class="{
                              'mobile-heading-expanded':
                                section.heading.length > 18,
                            }"
                          >
                            {{ section.heading }}
                          </h1>
                          <p
                            class="font-weight-lighter mb-0 section-text"
                            v-if="expanded === section.id"
                          >
                            {{ section.text }}
                          </p>
                        </template>
                      </div>
                      <template v-else>
                        <h1
                          v-if="expanded !== section.id"
                          class="font-weight-lighter heading-text"
                          v-html="elipsis(section.heading, 24)"
                        >
                          {{ section.heading }}
                        </h1>
                        <p
                          v-if="section.type === 'librarysection'"
                          class="
                            c-subtitle-asset
                            type-text
                            subtitle-text
                            pb-0
                            mb-0
                             text-uppercase
                          "
                        >
                          Discover
                        </p>
                        <p
                          v-else
                          class="
                           text-uppercase
                           type-text
                            c-subtitle-asset
                            subtitle-text
                            pb-0
                            mb-0
                          "
                        >
                          {{ section.type }}
                        </p>
                        <h1
                          v-if="expanded === section.id"
                          class="font-weight-lighter heading-text heading-text-expanded mt-lg-4"
                          v-html="elipsis(section.heading, 24)"
                        >
                          {{ section.heading }}
                        </h1>
                        <p
                          class="font-weight-lighter mb-0 section-text mt-3"
                          v-if="expanded === section.id"
                        >
                          {{ section.text }}
                        </p>
                      </template>
                    </div>
                  </b-col>
                </b-row>

                <!-- Mobile -->
                <div
                  v-if="isMobile()"
                  class="d-block mx-auto text-center pt-5"
                  v-show="
                    showByIndex === section.id ||
                      showByBlockIndex === section.id
                  "
                  style="position:relative;"
                >
                  <h1
                    class="text-light font-weight-lighter"
                    style="font-size: 1.5em"
                  >
                    {{ section.heading }}
                  </h1>
                  <p
                    class="text-light font-weight-lighter"
                    style="font-size: 1em"
                  >
                    {{ section.text }}
                  </p>

                  <div>
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'song'"
                      @click="
                        viewSection({
                          name: 'Song',
                          params: { id: section.id },
                        })
                      "
                      size="sm"
                      class="
                        text-light
                        px-3
                        font-weight-light
                        view-button-mobile
                        button-grey
                      "
                      squared
                      >View Song</b-button
                    >

                    <!-- Artist -->
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'artist'"
                      @click="
                        viewSection({
                          name: 'ArtistUsername',
                          params: { id: section.username },
                        })
                      "
                      size="sm"
                      class="
                        text-light
                        font-weight-light
                        view-button-mobile
                        button-blue
                      "
                      squared
                      >View Artist</b-button
                    >
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'playlist'"
                      @click="
                        viewSection({
                          name: 'Playlist',
                          params: { id: section.id },
                        })
                      "
                      size="sm"
                      variant="warning"
                      class="text-light font-weight-light view-button-mobile"
                      squared
                      >View Playlist</b-button
                    >
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'genre'"
                      @click="
                        viewSection({
                          name: 'LibrarySubSectionList',
                          params: { id: section.id },
                          query: { type: section.type },
                        })
                      "
                      size="sm"
                      variant="info"
                      class="
                        text-light
                        px-4
                        font-weight-light
                        view-button-mobile
                      "
                      squared
                      >View</b-button
                    >
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'librarysection'"
                      @click="
                        viewSection({
                          name: 'LibrarySectionList',
                          params: { area: section.side, id: section.id },
                        })
                      "
                      size="sm"
                      variant="info"
                      class="
                        text-light
                        px-4
                        font-weight-light
                        view-button-mobile
                      "
                      squared
                      >View</b-button
                    >
                    <b-button
                      v-show="showByIndex === section.id"
                      v-if="section.type === 'location'"
                      @click="
                        viewSection({
                          name: 'LibrarySubSection',
                          params: { id: section.id },
                          query: { type: section.location_type },
                        })
                      "
                      size="sm"
                      variant="info"
                      class="
                        text-light
                        px-4
                        font-weight-light
                        view-button-mobile
                      "
                      squared
                      >View</b-button
                    >
                    <template>
                      <playerbutton
                        v-if="
                          section.type === 'song' && expanded === section.id
                        "
                        v-bind:id="section.id"
                        v-bind:file="section.file"
                        v-bind:title="section.heading"
                        v-bind:username="section.username"
                        v-on:click.stop.native="closeSection()"
                      ></playerbutton>
                      <playerbutton
                        v-if="
                          section.type !== 'song' && expanded === section.id
                        "
                        v-bind:id="section.id"
                        v-bind:username="section.username"
                        v-bind:type="section.type"
                        v-on:click.stop.native="closeSection()"
                      ></playerbutton>
                      <!-- <playerbutton  v-if="section.type === 'song' && showByIndex === section.id" v-bind:id="section.id" v-bind:file="section.file" v-bind:title="section.heading" v-bind:username="section.username" v-on:click.stop.native="closeSection()"></playerbutton> -->
                      <!-- <playerbutton  v-if="expanded === section.id" v-bind:id="section.id" v-bind:username="section.username" v-bind:type="section.type" ></playerbutton> -->
                    </template>
                  </div>
                </div>
              </div>

              <div class="button-margin">
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'artist'"
                  @click="
                    viewSection({
                      name: 'ArtistUsername',
                      params: { id: section.username },
                    })
                  "
                  size="lg"
                  class="
                            text-light
                            py-2
                            
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-blue
                          "
                  squared
                  >View Artist</b-button
                >
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'song'"
                  @click="
                    viewSection({
                      name: 'Song',
                      params: { id: section.id },
                    })
                  "
                  size="lg"
                  variant="secondary"
                  class="
                            text-light
                            py-2
                            
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-grey
                          "
                  squared
                  >View Song</b-button
                >
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'playlist'"
                  @click="
                    viewSection({
                      name: 'Playlist',
                      params: { id: section.id },
                    })
                  "
                  size="lg"
                  variant="warning"
                  class="
                            text-light
                            py-2
                            
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-orange
                          "
                  squared
                  >View Playlist</b-button
                >
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'genre'"
                  @click="
                    viewSection({
                      name: 'LibrarySubSectionList',
                      params: { id: section.id },
                      query: { type: section.type },
                    })
                  "
                  size="lg"
                  variant="info"
                  class="
                            text-light
                            py-2
                            px-4
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-blue
                          "
                  squared
                  >View</b-button
                >
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'librarysection'"
                  @click="
                    viewSection({
                      name: 'LibrarySectionList',
                      params: { area: section.side, id: section.id },
                    })
                  "
                  size="lg"
                  variant="info"
                  class="
                            text-light
                            py-2
                            px-4
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-blue
                          "
                  squared
                  >View</b-button
                >
                <b-button
                  v-show="showByBlockIndex === section.id"
                  v-if="section.type === 'location'"
                  @click="
                    viewSection({
                      name: 'LibrarySubSection',
                      params: { id: section.id },
                      query: { type: section.location_type },
                    })
                  "
                  size="lg"
                  variant="info"
                  class="
                            text-light
                            py-2
                            px-4
                            mt-lg-4
                            mt-md-0
                            font-weight-light
                            view-button
                            button-blue
                          "
                  squared
                  >View</b-button
                >
                <span>
                  <playerbutton
                    v-if="section.type === 'song' && expanded === section.id"
                    v-bind:id="section.id"
                    v-bind:file="section.file"
                    v-bind:title="section.heading"
                    v-bind:username="section.username"
                    v-bind:type="'song'"
                    v-on:click.stop.native="closeSection()"
                  ></playerbutton>
                  <playerbutton
                    v-else-if="
                      section.type === 'playlist' && expanded === section.id
                    "
                    v-bind:id="section.id"
                    v-bind:queue="section.songs"
                    v-bind:title="section.heading"
                    v-bind:username="section.username"
                    v-bind:type="'playlist'"
                    v-on:click.stop.native="closeSection()"
                  ></playerbutton>
                  <playerbutton
                    v-else-if="
                      section.type === 'artist' && expanded === section.id
                    "
                    v-bind:id="section.id"
                    v-bind:queue="section.featured_tracks"
                    v-bind:title="section.heading"
                    v-bind:username="section.username"
                    v-bind:type="'artist'"
                    v-on:click.stop.native="closeSection()"
                  ></playerbutton>
                  <playerbutton
                    v-else-if="expanded === section.id"
                    v-bind:id="section.id"
                    v-bind:username="section.username"
                    v-bind:type="section.type"
                    v-on:click.stop.native="closeSection()"
                  ></playerbutton>
                </span>
              </div>
            </div>
          </div>
        </template>

        <infinite-loading @infinite="infiniteHandler">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>

      </b-row>



    </b-container>


  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapGetters, mapMutations } from "vuex";
import chipin from "../components/chipin.vue";
import chipinplus from "../components/chipinplus.vue";
import playerbutton from "../components/player-button.vue";

export default {
  name: "Home",
  components: {
    chipin,
    chipinplus,
    playerbutton,
    InfiniteLoading,
  },
  data() {
    return {
      sections: [],
      filtered_sections: [],
      showByIndex: null,
      showByBlockIndex: null,
      rowNeighbours: [],
      // The expanded section
      expanded: null,
      next_page: null,

      largeScreen: window.matchMedia("(min-width: 992px)").matches,
    };
  },
  methods: {
    ...mapActions(["get_data", "get_paginated_data"]),
    ...mapActions("Library", ["home", "home_filter"]),
    ...mapMutations(["setCameFromSignUp", "setCameFromCampaign"]),
    ...mapMutations("Library", ["setSections"]),
    ...mapMutations("Player", ["pauseSong", "setPlayAfterAuth", "setSong"]),
    ...mapMutations("Auth", ["setShowLoginModal", "setShowHomeOverlay"]),
    infiniteHandler($state) {
      if (this.next_page ) {
      this.get_paginated_data(this.next_page).then((res) => {
        this.sections.push(...res.data.sections);
        this.next_page = res.data.next;

        if (this.next_page) {
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch((error) => {
        error
        $state.complete();
      });
      } else {
            $state.complete();
      }
    },
    clickedSection(section) {
      this.showByIndex = section.id;
      this.expanded = section.id;
      if (!this.isMobile()) {
        this.showByBlockIndex = section.id;

        if (window.matchMedia("(min-width: 992px)").matches) {
          // Clear rowNeighbours array
          this.rowNeighbours.length = 0;
          // Set the neighbours of the clicked section

          // Determine the index of the section and the row it's in (4 sections per row)
          let clickedIndex = this.sections.indexOf(section);
          let rowNumber = Math.floor(clickedIndex / 4);
          // Determine which other sections are in the row
          let rowLower = rowNumber * 4;
          let rowUpper = rowLower + 3;
          // Find the row neighbours of the selected section
          for (let i = rowLower; i <= rowUpper; i++) {
            // Don't add the selected section as a neighbour
            if (i !== this.sections.indexOf(section)) {
              this.rowNeighbours.push(this.sections[i]);
            }
          }
        }
      }
    },
    closeSection() {
      this.showByIndex = null;
      this.showByBlockIndex = null;
      this.expanded = null;
      this.rowNeighbours.length = 0;
    },

    viewSection(route) {
      if (!this.isAuthenticated) {
        this.$bvModal.show("login-modal");
        this.$store.commit("storeNextUrl", route);
      } else {
        this.$router.push(route);
      }
    },

    getRowNeighbours(section) {
      let section_list;
      if (this.filter_applied) {
        section_list = this.getFilteredSections;
      } else {
        section_list = this.sections;
      }

      let neighbours = [];
      // Find the row neighbours of the selected section
      for (var i = 0; i <= section_list.length - 1; i++) {
        // Don't add the selected section as a neighbour
        if (i !== section_list.indexOf(section)) {
          neighbours.push(section_list[i]);
        }
      }
      return neighbours;
    },
  },
  mounted() {
    this.home()
      .then((res) => {
        this.sections = res.data.sections;
        this.setSections(res.data.sections);

        if (res.data.next) {
          this.next_page = res.data.next;
        }
        
        // If unauthenticated, show the full screen modal overlay
        if (!this.isAuthenticated) {
          this.setCameFromSignUp(false);
          this.setCameFromCampaign(false);
        }
      })
      .catch((error) => {
        error;

        if (error.status === 401) {
          localStorage.clear();

          this.home().then((res) => {
            this.sections = res.data.sections;
            this.setSections(res.data.sections);
          });
        } else {
          this.$router.replace("/error");
        }
      });
  },
  computed: {
    ...mapGetters("Library", {
      getSections: "getSections",
      getFilteredSections: "getFilteredSections",
      filter_applied: "filter_applied",
    }),
    ...mapGetters(["isAuthenticated", "showModal", "cameFromSignUp", "cameFromCampaign"]),
    ...mapGetters("Auth", {
      loginModalDisplayed: "login_modal_displayed",
      homeOverlayDisplayed: "home_overlay_displayed",
    }),
    ...mapGetters("Player", {
      getLoadedSong: "getLoadedSong",
      getLoadedSongData: "getLoadedSongData",
      playing: "isPlaying",
      initialPlay: "isInitialPlay",
      getCurrentPlaylist: "getCurrentPlaylist",
      shouldPlayAfterAuth: "shouldPlayAfterAuth",
      getPlayAfterAuth: "getPlayAfterAuth",
    }),

  },
  watch: {
    getSections() {
      this.sections = this.getSections;
    },

    getFilteredSections() {
      this.filtered_sections = this.getFilteredSections;
    },

    filter_applied() {
      return this.filter_applied;
    },

    isAuthenticated() {
      if (this.shouldPlayAfterAuth && this.isAuthenticated) {
        this.pauseSong();
        this.setSong(this.getPlayAfterAuth);
        this.playSong();
        this.setPlayAfterAuth({ shouldPlay: false, songToPlay: null });
      }
    },
  },
};
</script>
<style scoped>
.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
.overlay {
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);
}
.overlay:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/* .square-normal-overlay {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.6)
  );
} */
.vh-30 {
  height: 30vh !important;
}
.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}
.pt-6,
.py-6 {
  padding-top: 6rem !important;
}
.pb-7,
.py-7 {
  padding-bottom: 7rem !important;
}
.pt-7,
.py-7 {
  padding-top: 7rem !important;
}
.pb-8,
.py-8 {
  padding-bottom: 8rem !important;
}
.pt-8,
.py-8 {
  padding-top: 8rem !important;
}

.section {
  height: 160px;
}

.hide-section {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  display: none;
}

.section-info {
  margin-top: 32px;
}

.heading-text {
  font-size: 24px;
  line-height: 36px;
}

.type-text {
  font-size: 16px;
  /* line-height: 20px; */
  font-weight: 300;
}

.button-blue {
  background-color: #00afdc;
}
.button-orange {
  background-color: #eab67b;
}
.button-grey {
  background-color: #4a4a4a;
}

.button-margin {
  width: 100%;
  position: absolute;
  bottom: 0px;

  display: flex;
  justify-content: center;
}

.view-button {
  width: 106px;
  height: 40px;
  font-size: 0.9em;
  text-align: center;
  border: none;
  padding: 0;
}

.player-margin {
  padding-bottom: 30px;
}

.expanded-align {
  position: absolute;
  left: 0em;
}

.square-normal-overlay {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.4)
  );
}

.close-button {
  margin-right: 10px;
}

.section-normal {
  position: absolute;
  width: 100%;
  /* bottom: 60px; */
}

.c-item-information {
  height: 19vw;
}

.c-subtitle-asset {
  text-transform: uppercase;
}

.subtitle-text {
  font-size: 14px;
}

@media (max-width: 565px) {
  .c-subtitle-asset {
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
  }

  .mobile-heading-expanded {
    font-size: 24px;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .section-top {
    display: none;
    transform: translateY(50%);
  }

  .type-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
  }

  .c-item-information {
    height: 160px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-banner {
    height: 100%;
  }

  .c-item-information {
    height: 149px;
  }
  .section {
    height: 200px;
  }

  .section-top {
    height: 35px;
    transform: translateY(50%);
  }

  .section-normal {
    position: absolute;
    width: 100%;
    top: -20%;
  }

  .button-margin {
    width: 100%;
  }

  .heading-text {
    font-size: 28px;
  }

  .type-text {
    font-size: 14px;
    line-height: 30px;
    font-weight: 900;
  }

  .section-expanded {
    margin-top: -6px !important;
  }

  .view-button {
    border: none;
    width: 134px;
    height: 52px;
    font-size: 24px;
    margin-top: 1.5em;
  }

  .square-normal-overlay {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.4)
    );
  }
}

@media (min-width: 992px) {
  .hero-banner {
    height: 25vw;
    width: 100%;
  }
  .section {
    height: 100%;
    width: 100%;
  }

  .section-normal {
    position: absolute;
    bottom: -10px;
  }

  .heading-text {
    font-size: 24px;
    margin-bottom: none;
    line-height: 20px;
  }

  .heading-text-expanded {
    font-size: 54px;
  }
  .c-item-information {
    height: 18vw;
  }

  .type-text {
    font-size: 14px;
    font-weight: 900;
  }

  .section-text {
    font-size: 24px;
  }

  .section-expanded {
    margin-top: 1em !important;
  }

  .button-margin {
    width: 100%;
  }

  .view-button {
    border: none;
    width: 187px;
    height: 60px;
    font-size: 1.6em;
  }

  .wide-section {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }

  .section-top {
    height: 35px;
    transform: translateY(40%);
  }

  /* .square-normal-overlay {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      rgba(0, 0, 0, 0.6)
    );
  } */
}

@media (min-width: 1200px) {
  .c-item-information {
    height: 19vw;
  }

  .button-margin {
    width: 100%;
  }

  .section-expanded {
    margin-top: 2em !important;
  }

  .heading-text {
    line-height: 30px;
  }

  .section-normal {
    bottom: -7px;
  }
}
</style>
